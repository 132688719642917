<template>
  <div>
    <v-container>
      <v-row
        class="fill-height wrapper-pages"
        align-content="center"
        justify="center"
      >
        <v-col
          class="text-h5"
          cols="12"
        >
          {{ content[0].title }}
        </v-col>
        <v-col
          class="text-subtitle-1"
          cols="12" v-html="content[0].description"
        ></v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import { mdiChevronDown, mdiCloudUpload, mdiAccountTie, mdiAccount, mdiAccountMultiple } from '@mdi/js';

export default {
  name: "Content",
  directives: {
  },
  components: {
  },
  data: () => ({
    /* Icons */
    mdiChevronDown,
    mdiCloudUpload,
    mdiAccountTie,
    mdiAccount,
    mdiAccountMultiple,
    /* ---- */
    endpoint: process.env.VUE_APP_ROOT_API,
    content: [{
      title: '',
      description: '',
    }],
    active: false,
    logged:false,
  }),
  watch: {
    '$route.params.page_id'(newId, oldId) {
      this.getPage(newId)
    },
  },
  mounted() {
    this.checkLogin();
    this.getPage(this.$route.params.page_id);
  },
  methods: {
    checkLogin() {
      this.$store.commit('setLogin', true);
      this.active = true;
      this.logged =  true;
    },
    getPage(page) {
      axios.get(`${this.endpoint}/getPage?page_id=${page}`).then(response => {
        this.content = response.data.content;
      })
      .catch(() => { /* ERROR */ });
    },
  }
};
</script>

<style lang="scss">
  .wrapper-pages {
    max-width: 1009px;
    margin: 0 auto;
    font-size: 1rem !important;
    font-weight: normal;
    line-height: 1.75rem;
    letter-spacing: 0.009375em !important;
    font-family: "Inter", sans-serif !important;
    text-align: justify;
    * {
      font-size: 1rem !important;
      font-weight: normal;
      line-height: 1.75rem;
      letter-spacing: 0.009375em !important;
      font-family: "Inter", sans-serif !important;
    }

    ol {
      margin-left: 20px;
      li {
        padding-bottom: 20px;
      }
    }
  }
</style>
